<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="save()"
        >
          <v-row>
            <v-col cols="12" md="12" class="pb-0">
              <v-text-field
                v-model.trim="nombre"
                label="Nombre"
                dense
                outlined
                :rules="
                  rules.required.concat([
                    rules.requiredTrim(nombre),
                    rules.maxLength(nombre, 50)
                  ])
                "
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" class="pb-0">
              <v-text-field
                v-model="sssCodigo"
                label="Código SSS"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                dense
                outlined
                @change="sameSssCode()"
                :rules="[rules.maxLength(sssCodigo, 2)]"
                autocomplete="off"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
      <ConfirmDialog
        :text="textConfirm"
        :openConfirm.sync="openModalSaveConfirm"
        @update:openConfirm="cancel"
        @onConfirm="confirmSave"
      />
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import ConfirmDialog from "@/components/shared/ConfirmDialog";

export default {
  name: "EditEstadoCivil",
  components: { ConfirmDialog },
  props: {
    estadoCivilObject: {
      type: Object,
      default: null
    },
    configEstadosCiviles: {
      type: Array,
      default: null
    }
  },
  data: () => ({
    isFormValid: false,
    formEditTitle: enums.titles.EDITAR_ESTADO_CIVIL,
    rules: rules,
    banSameCodigo: false,
    estcivilId: null,
    nombre: null,
    sssCodigo: null,
    textConfirm: enums.messages.SAME_CODIGO_SSS,
    isNew: false,
    openModalSaveConfirm: false
  }),
  created() {
    if (this.estadoCivilObject) {
      this.setEstadoCivil();
    } else {
      this.isNew = true;
      this.newEstadoCivil();
    }
  },
  methods: {
    ...mapActions({
      postEstadoCivil: "configAfiliaciones/postEstadosCiviles",
      setAlert: "user/setAlert"
    }),
    async newEstadoCivil() {
      this.formEditTitle = enums.titles.NUEVO_ESTADO_CIVIL;
    },
    setEstadoCivil() {
      this.nombre = this.estadoCivilObject.estcivilNombre;
      this.sssCodigo = this.estadoCivilObject.sssCodigo.toString();
      this.estcivilId = this.estadoCivilObject.estcivilId;
    },
    sameSssCode() {
      this.banSameCodigo = false;
      if (this.sssCodigo != null) {
        let sameCodeSss = parseInt(this.sssCodigo);
        this.configEstadosCiviles.every(estadocivil => {
          if (estadocivil.sssCodigo === sameCodeSss) {
            if (this.isNew) {
              this.banSameCodigo = true;
              return false;
            } else {
              if (estadocivil.estcivilId != this.estcivilId) {
                this.banSameCodigo = true;
                return false;
              }
            }
          }
          return true;
        });
      }
    },
    async save() {
      this.sameSssCode();
      const data = {
        estcivilId: this.estadoCivilObject?.estcivilId,
        estcivilNombre: this.nombre,
        sssCodigo: this.sssCodigo
      };
      if (!this.sssCodigo) data.sssCodigo = 0;
      if (this.banSameCodigo) {
        this.openModalSaveConfirm = true;
      } else {
        const res = await this.postEstadoCivil(data);
        if (res.status === 200) {
          setTimeout(() => {
            this.setAlert({ type: "success", message: "Guardado con éxito" });
          }, 1200);
        }
        this.closeModal();
      }
    },
    async confirmSave() {
      const res = await this.postEstadoCivil(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
      }
      this.closeModal();
    },
    cancel() {
      this.openModalSaveConfirm = false;
    },

    closeModal() {
      this.openModalSaveConfirm = false;
      this.$emit("closeAndReload");
    }
  }
};
</script>

<style></style>
